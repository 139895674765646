import getActivity from './getActivity'
import useCity from './useCity'

const WhatsappURL = (isDiscount = false) => {
  const activity = getActivity()
  const city = useCity()

  const baseUrl = 'https://wa.me/447472016999'
  let message = 'Hello! I would like book a watersport activity'

  if (activity !== null) {
    message = `Hello! I would like to book a ${activity}`
  }

  if (city !== 'your location') {
    message += ` in ${city}`
  }

  if (isDiscount) {
    message += ' using the discount code WELCOME20'
  }

  return `${baseUrl}?text=${message}`
}

export default WhatsappURL
