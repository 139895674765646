import React, { useState, useEffect, useRef } from 'react'

import { CountryToCurrency } from 'constant'
import { useSearch } from 'contexts/searchContext'
import Currency from 'features/common/components/layouts/Header/Currency'
import Nav from 'features/common/components/layouts/Nav'
import getActivity from 'features/common/helpers/getActivity'
import useCity from 'features/common/helpers/useCity'
import WhatsappURL from 'features/common/helpers/whatsappURL'
import { useBookSummaryService } from 'slices/bookSummary/hooks'
import { getCountry } from 'slices/currency/api'
import { useCurrencyService } from 'slices/currency/hooks'

const Header = () => {
  const {
    bookSummary: { selectDateTime },
    bookSummaryInit,
  } = useBookSummaryService()
  const {
    currency: { currencies, defaultCurrencyString },
    getCurrencies,
    setSelectedCurrency,
  } = useCurrencyService()
  const activity = getActivity()
  const city = useCity()
  const whatsappUrl = WhatsappURL()
  const { setShow } = useSearch()

  const [navOpen, setNavOpen] = useState<boolean>(false)
  const [showCurrencyNav, setShowCurrencyNav] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const toggleNavOpen = () => {
    setNavOpen(!navOpen)
  }

  function clickHeader() {
    if (selectDateTime) {
      setNavOpen(false)
    } else {
      window.location.href = 'https://w-sports.click/'
    }
  }

  useEffect(() => {
    getCurrencies()
  }, [city, getCurrencies])

  useEffect(() => {
    const getCurrency = async () => {
      try {
        const { country } = await getCountry()
        const currencyCode = CountryToCurrency[country] || ''
        const newCurrency =
          currencies.find(item => item.code.includes(currencyCode)) || currencies[0]
        setSelectedCurrency(newCurrency)
      } catch (e) {
        setSelectedCurrency(currencies[0])
      }
    }

    getCurrency().then()
  }, [currencies, defaultCurrencyString, setSelectedCurrency])

  return (
    <div className="fixed left-0 top-0 right-0 bottom-auto bg-white flex flex-col-reverse w-nav">
      <div
        ref={containerRef}
        className="w-11/12 max-w-7xl mx-auto py-1.5 px-4 flex items-center justify-between fixed top-0 bg-white md:w-full md:py-2 w-container md:shadow-[0px_4px_4px_rgba(0,0,0,0.25)]"
      >
        <a href="#" className="pl-0 flex items-center w-nav-brand" onClick={clickHeader}>
          {selectDateTime ? (
            <span onClick={() => bookSummaryInit()}>
              <img
                src={`${process.env.PUBLIC_URL}/static/images/arrow_back_ios.svg`}
                loading="lazy"
                alt=""
              />
            </span>
          ) : (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/static/images/header__waves-logo.svg`}
                loading="lazy"
                alt=""
                className="w-16 h-16 object-contain md:w-8 md:h-8"
              />
              {activity && city !== 'your location' ? (
                <div
                  className={`text-4xl font-medium leading-none italic lg:ml-2.5 lg:text-2xl lg:text-gray-900 lg:not-italic ${
                    city.length > 10 ? 'flex flex-col' : ''
                  }`}
                >
                  <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                    {activity}
                  </span>
                  <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                    {` in ${city}`}
                  </span>
                </div>
              ) : (
                <div className="text-4xl font-medium leading-none italic lg:ml-2.5 lg:text-xl lg:text-gray-900 lg:not-italic">
                  WATERSPORTS <span className="font-light text-teal-500">everywhere</span>
                </div>
              )}
            </>
          )}
        </a>
        <div className="ml-auto flex items-center !z-10 bg-white p-0 block md:hidden">
          <div className="mr-5 text-gray-700 flex items-center">
            <img
              src={`${process.env.PUBLIC_URL}/static/images/ic_phone.png`}
              loading="lazy"
              alt=""
              className="w-4 h-4 mr-2"
            />
            +44 74 7201 6999
          </div>
          <a
            href={whatsappUrl}
            id="btn-header-whatsapp-now"
            target="_blank"
            className="w-48 h-12 bg-green-500 rounded my-2.5 mr-5 py-0 px-4 text-base font-medium text-white text-center no-underline flex items-center justify-center hover:opacity-80"
            rel="noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/static/images/Whatssap-icon.svg`}
              loading="lazy"
              alt=""
              className="w-8 h-8 mr-2"
            />
            <div>WhatsApp Now</div>
          </a>
          <a
            href="#"
            id="btn-header-search-now"
            className="w-40 h-12 bg-gray-800 rounded border-0 my-2.5 py-0 px-4 text-base font-medium text-white text-center no-underline cursor-pointer flex items-center justify-center"
            onClick={() => setShow(true)}
          >
            Search now
          </a>
        </div>

        <div
          className="hidden h-11 md:ml-auto md:flex md:items-center"
          onClick={() => setShowCurrencyNav(!showCurrencyNav)}
        >
          <img
            className="w-8 h-8"
            src={`${process.env.PUBLIC_URL}/static/images/currency.svg`}
            alt="Currency Icon"
          />
        </div>
        <div
          className="hidden h-11 w-11 md:ml-2 md:flex md:items-center md:justify-end"
          onClick={toggleNavOpen}
        >
          {!navOpen ? (
            <img
              src={`${process.env.PUBLIC_URL}/static/images/menu-icon.svg`}
              loading="lazy"
              alt="Menu Icon"
              className="w-8 h-8 block"
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/static/images/ic_close.png`}
              loading="lazy"
              alt=""
              className="w-6 h-6 block"
            />
          )}
        </div>
      </div>
      <Nav
        toggleNavOpen={toggleNavOpen}
        show={navOpen}
        topPadding={containerRef.current?.clientHeight}
      />
      {<Currency setShowCurrencyNav={setShowCurrencyNav} show={showCurrencyNav} />}
    </div>
  )
}

export default Header
